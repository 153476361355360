<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import stepsMixin from '@/mixins/stepsMixin'
const parseKeys = require('../../parseKeys.json')
export default {
	mixins: [stepsMixin],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
		FormAnalysisBasicGroup: () => import('@/components/simulator/arce/ArceForms/AnalysisBasicGroup'),
		FormAnalysisBasic: () => import('@/components/simulator/arce/ArceForms/AnalysisBasic'),
		FormBusinessRiskGroup: () => import('@/components/simulator/arce/ArceForms/AnalysisBusinessRiskGroup'),
		FormBusinessRisk: () => import('@/components/simulator/arce/ArceForms/AnalysisBusinessRisk'),
		FormAnalysisIndustry: () => import('@/components/simulator/arce/ArceForms/AnalysisIndustry'),
		FormAnalysisFinancialRisk: () => import('@/components/simulator/arce/ArceForms/AnalysisFinancialRisk'),
		FormAnalysisFinancialRiskGroup: () => import('@/components/simulator/arce/ArceForms/AnalysisFinancialRiskGroup'),
		FormRating: () => import('@/components/simulator/arce/ArceForms/FormRating'),
		SumaryBankRelationship: () => import('@/components/simulator/arce/ArceForms/SumaryBankRelationship'),
		SumaryBankRelationshipGroup: () => import('@/components/simulator/arce/ArceForms/SumaryBankRelationshipGroup'),
		FormEvaluation: () => import('@/components/simulator/arce/ArceForms/FormEvaluation'),
		FormEvaluationGroup: () => import('@/components/simulator/arce/ArceForms/FormEvaluationGroup'),
		FormPolicies: () => import('@/components/simulator/arce/ArceForms/FormPolicies'),
		FormPoliciesGroup: () => import('@/components/simulator/arce/ArceForms/FormPoliciesGroup'),
	},

	data() {
		return {
			selected: null,
			selectedDetails: null,
			validationIntro: true,
			validationResult: false,
			proposedSancion: '',
			loaderProposed: false,

			json_fields: {
				'Campo': 'field',
				'Datos': 'data',
			},

			json_policies_fields: {
				'Tipologia': 'tipologia',
				'Último Autorizado': 'ultimoAutorizado',
				'Comprometido': 'comprometido',
				'No Comprometido': 'noComprometido',
				'Límite Actual': 'limiteActual',
				'Dispuesto': 'dispuesto',
				'Propuesto': 'propuesto',
			},

			json_meta: [
				[
					{
						'key': 'charset',
						'value': 'utf-8'
					}
				]
			],
		}
	},

	computed: {
		
		group() {
			if(this.arce) {
				return this.clienById(this.arce.groupId)
			}
			return null
		},

		checkAllForms() {

			let forms = ['rating_actual', 'analisis_basico', 'analisis_riesgo_del_negocio', 'analisis_riesgo_financiero', 'analisis_industria', 'evaluation_arce', 'sumary_bank_relationship']

			if(!this.selectedDetails) {
				form = ['analisis_basico', 'analisis_riesgo_del_negocio', 'analisis_riesgo_financiero', 'evaluation_arce', 'sumary_bank_relationship']
			} else if(this.selectedDetails.type != 'Cliente') {
				forms = ['analisis_basico', 'analisis_riesgo_del_negocio', 'analisis_riesgo_financiero', 'evaluation_arce', 'sumary_bank_relationship']
			}

			let fill = 0
			forms.forEach(f => {
				fill += this.getFill(f)
			})

			return (fill / forms.length) == 100
		},


		...mapGetters({
			arce: 'arceDetails/arce',
			clienById: 'arceClients/getById',
			userName: 'auth/userName'
		})
	},

	// watch: {
	// 	group: {
	// 		handler(nVal) {
	// 			this.selected = nVal.id
	// 		},
	// 		deep: true
	// 	}
	// },


	methods: {
		initWizard() {			
			this.initArceWizard({
				data: {
					start: true,
					client: {},
				},
				steps: [
					{
						sort: 1,
						key: 'start',
						status: 'current'
					},
				]
			})

			this.showArceWizard()
		},
		excelPoliciesData() {
			if(!this.arce && !this.group) {
				return []
			}

			// 'Tipologia': 'tipologia',
			// 'Último Autorizado': 'ultimoAutorizado',
			// 'Comprometido': 'comprometido',
			// 'No Comprometido': 'noComprometido',
			// 'Límite Actual': 'limiteActual',
			// 'Dispuesto': 'dispuesto',
			// 'Propuesto': 'propuesto',
			let data = []

			if(this.arce.arce.hasOwnProperty('policies')) {
				let policies = this.arce.arce.policies
				data.push(...[
					{
						tipologia: `RESUMEN ${this.group.name}`,
						ultimoAutorizado: `Moneda: ${policies.moneda}`,
						comprometido: `Datos expresados en: ${policies.datosEn}`,
						noComprometido: '',
						limiteActual: '',
						dispuesto: '',
						propuesto: '',
					}, {
						tipologia: `Tipología`,
						ultimoAutorizado: `Último Autorizado`,
						comprometido: `Comprometido`,
						noComprometido: 'No Comprometido',
						limiteActual: 'Límite Actual',
						dispuesto: 'Dispuesto',
						propuesto: 'Propuesto',
					}
				])

				data.push(...policies.politicas.map(p => {
					return {
						tipologia: p.tipologia,
						ultimoAutorizado: p.ultimoAutorizado,
						comprometido:  p.comprometido,
						noComprometido: p.noComprometido,
						limiteActual: p.limiteActual,
						dispuesto: p.dispuesto,
						propuesto: p.propuesto,
					}
				}))

				data.push({
					tipologia: '',
					ultimoAutorizado: '',
					comprometido:  '',
					noComprometido: '',
					limiteActual: '',
					dispuesto: '',
					propuesto: '',
				})

			}

			this.arce.children.forEach(ch => {
				if(ch.arce.hasOwnProperty('policies')) {
					let policies = ch.arce.policies

					data.push(...[
						{
							tipologia: `RESUMEN ${ch.name}`,
							ultimoAutorizado: `Moneda: ${policies.moneda}`,
							comprometido: `Datos expresados en: ${policies.datosEn}`,
							noComprometido: '',
							limiteActual: '',
							dispuesto: '',
							propuesto: '',
						}, {
							tipologia: `Tipología`,
							ultimoAutorizado: `Último Autorizado`,
							comprometido: `Comprometido`,
							noComprometido: 'No Comprometido',
							limiteActual: 'Límite Actual',
							dispuesto: 'Dispuesto',
							propuesto: 'Propuesto',
						}
					])
					data.push(...policies.politicas.map(p => {
						return {
							tipologia: p.tipologia,
							ultimoAutorizado: p.ultimoAutorizado,
							comprometido:  p.comprometido,
							noComprometido: p.noComprometido,
							limiteActual: p.limiteActual,
							dispuesto: p.dispuesto,
							propuesto: p.propuesto,
						}
					}))

					data.push({
						tipologia: '',
						ultimoAutorizado: '',
						comprometido:  '',
						noComprometido: '',
						limiteActual: '',
						dispuesto: '',
						propuesto: '',
					})
				}
			})
			if(data.length) {
				this.downloadExcel({
					name: `ARCE_Tabla_politicas_${this.userName}_${this.group.name}_${this.arce.folio}`,
					fields: [{sheetid:'One',header:false}],
					data: [data]
				})
			}

			return data
		},
		excelData() {
			if(!this.arce && !this.group) {
				return []
			}
			let data = [
				{
					field: 'Grupo Económico',
					data: this.group ? this.group.name : ''
				},
				{
					field: 'Datos Generales',
					data: '',
				},
				{
					field: 'Folio',
					data: this.arce.folio,
				},
				{
					field: 'Estado',
					data: this.arce.status,
				},
				{
					field: '',
					data: '',
				},
			]
			let c = this.arce


				if(c.arce.hasOwnProperty('analisis_basico')) {
					data.push(...[
						{
							field: parseKeys['analisis_basico']['analisis_basico'],
							data: '',
						},
						
					])
					let labels = parseKeys['analisis_basico']
					let arce = c.arce['analisis_basico']

					for (const key in arce) {
						if(key == 'filiales') {
							data.push(...[
								{
									field: '',
									data:  '',
								},
								{
									field: 'Filiales',
									data:  '',
								},
								{
									field: '',
									data:  '',
								}
								
							])

							arce[key].forEach(item => {
								if(item.nombre && item.participacion) {
									
									data.push({
										field: 'Nombre',
										data:  item.nombre,
									})
									data.push({
										field: 'Participación',
										data:  item.participacion,
									})
									data.push({
										field: 'Actividad',
										data:  item.actividad,
									})
									data.push({
										field: 'Cliente BBVA',
										data:  item.clienteBBVA,
									})
									data.push({
										field: '',
										data:  '',
									})
								}
							})

						} else if(key == 'shareholders') {
							data.push({
								field: 'Accionistas',
								data:  '',
							})
							arce[key].forEach(sh => {
								if(sh.nombre && sh.participacion) {
									data.push({
										field: '',
										data:  '',
									})
									data.push({
										field: 'Nombre',
										data:  sh.nombre,
									})
									data.push({
										field: 'Participación',
										data:  sh.participacion,
									})
									data.push({
										field: 'Año',
										data:  sh.ano,
									})
									data.push({
										field: 'Año',
										data:  sh.financialSponsor,
									})
								}
							})
						} else {
							data.push({
								field: labels[key] || key,
								data: arce[key],
							})
						}
					}
				} 
				if(c.arce.hasOwnProperty('analisis_riesgo_del_negocio')){
					data.push(...[
						{
							field: parseKeys['analisis_riesgo_del_negocio']['analisis_riesgo_del_negocio'],
							data: '',
						},
						
					])
					let labels = parseKeys['analisis_riesgo_del_negocio']
					let arce = c.arce['analisis_riesgo_del_negocio']

					for (const key in arce) {
						if(!(['geografias', 'actividades', 'principalesClientes'].includes(key))) {
							data.push({
								field: labels[key] || key,
								data: arce[key],
							})
						} else if(key == 'geografias') {
							data.push({
								field: 'Geografías',
								data:  '',
							})
							arce[key].forEach(item => {
								if(item.nombre && item.porcentaje) {
									data.push(...[
										{
											field: 'Nombre',
											data: item.nombre,
										},
										{
											field: 'Porcentaje',
											data: item.porcentaje,
										},
										{
											field: 'Año',
											data: item.ano,
										}
									])
								}
							})
						} else if(key == 'actividades') {
							data.push({
								field: 'Actividades',
								data:  '',
							})

							arce[key].forEach(item => {
								if(item.nombre && item.porcentaje) {
									data.push(...[
										{
											field: 'Nombre',
											data: item.nombre,
										},
										{
											field: 'Porcentaje',
											data: item.porcentaje,
										},
										{
											field: 'Año',
											data: item.ano,
										}
									])
								}
							})
						}  else if(key == 'principalesClientes') {
							data.push({
								field: 'Pricipales clientes ',
								data:  '',
							})

							arce[key].forEach(item => {
								if(item.nombre && item.porcentaje) {
									data.push(...[
										{
											field: 'Nombre',
											data: item.nombre,
										},
										{
											field: 'Participación',
											data: item.participacion,
										}
									])
								}
							})
						}
					}
				} 
				if(c.arce.hasOwnProperty('analisis_industria')) {
					data.push(...[
						{
							field: parseKeys['analisis_industria']['analisis_industria'],
							data: '',
						},
						
					])
					let labels = parseKeys['analisis_industria']
					let arce = c.arce['analisis_industria']
					for (const key in arce) {
						data.push({
							field: labels[key] || key,
							data: arce[key],
						})
					}

				}
				if(c.arce.hasOwnProperty('analisis_riesgo_financiero')) {
					data.push(...[
						{
							field: parseKeys['analisis_riesgo_financiero']['analisis_riesgo_financiero'],
							data: '',
						},
						
					])
					let labels = parseKeys['analisis_riesgo_financiero']
					let arce = c.arce['analisis_riesgo_financiero']
					for (const key in arce) {
						if(!['eeff', 'tablaVencimientos'].includes(key)) {
							data.push({
								field: labels[key] || key,
								data: arce[key],
							})
						} else if(key === 'tablaVencimientos') {
							data.push({
								field: 'Tabla Vencimientos',
								data:  '',
							})

							arce[key].forEach(item => {
								if(item.nombre && item.importe) {
									data.push(...[
										{
											field: 'Nombre',
											data: item.nombre,
										},
										{
											field: 'Importe',
											data: item.importe,
										},
										{
											field: 'Año',
											data: item.ano,
										}
									])
								}
							})
						}
					}

				}
				if(c.arce.hasOwnProperty('rating_actual')) {
					data.push(...[
						{
							field: parseKeys['rating_actual']['rating_actual'],
							data: '',
						},
						
					])
					let labels = parseKeys['rating_actual']
					let arce = c.arce['rating_actual']
					for (const key in arce) {
						data.push({
							field: labels[key] || key,
							data: arce[key],
						})
					}
				}
				if(c.arce.hasOwnProperty('sumary_bank_relationship')) {
					data.push(...[
						{
							field: parseKeys['sumary_bank_relationship']['sumary_bank_relationship'],
							data: '',
						},
						
					])

					let labels = parseKeys['sumary_bank_relationship']
					let arce = c.arce['sumary_bank_relationship']

					for (const key in arce) {
						if(!['bancos', 'buro', 'posicionesRiesgo'].includes(key)) {
							data.push({
								field: labels[key] || key,
								data: arce[key],
							})
						} else if(key == 'posicionesRiesgo') {

							data.push(...[
								{
									field: '',
									data: '',
								},
								{
									field: 'Resumen posición de riesgo',
									data:  '',
								}
							])
							arce[key].forEach(item => {
								data.push(...[
									{
										field: '',
										data: '',
									},
									{
										field: 'Producto',
										data: item.producto,
									},
									{
										field: 'Límite actual',
										data: item.limiteActual,
									},
									{
										field: 'Comprometido',
										data: item.comprometido,
									},
									{
										field: 'No comprometido',
										data: item.noComprometido,
									},
									{
										field: 'Dispuesto',
										data: item.dispuesto,
									},
									{
										field: 'Comentarios/Garantías',
										data: item.comentariosGarantias,
									},
								])
							})
						} else if(key == 'buro') {
							data.push(...[
								{
									field: '',
									data: '',
								},
								{
								field: 'Buró',
								data:  '',
								}
							])
							arce[key].forEach(item => {
								data.push(...[
									{
										field: '',
										data: '',
									},
									{
										field: 'Nombre',
										data: item.producto,
									},
									{
										field: 'Fecha',
										data: item.fecha,
									},
									{
										field: 'Clave Prevención',
										data: item.clavePrevencion,
									},
									{
										field: 'Número de Bancos',
										data: item.numeroBancos,
									},
									{
										field: 'M.N',
										data: item.mn,
									},
									{
										field: 'USD Val',
										data: item.usd,
									},
									{
										field: 'UDIS Val',
										data: item.udis,
									},
									{
										field: 'Hasta 30 d',
										data: item.h30,
									},
									{
										field: 'Hasta 90 d',
										data: item.h90,
									},
									{
										field: 'Más de 90 d.',
										data: item.m90,
									},
								])
							})
						} else if(key == 'bancos') {
							data.push(...[
								{
									field: '',
									data: '',
								},
								{
								field: 'Bancos',
								data:  '',
								}
							])
							arce[key].forEach(item => {
								data.push(...[
									{
										field: '',
										data: '',
									},
									{
										field: 'Nombre',
										data: item.nombre,
									},
									{
										field: 'Porcentaje',
										data: item.porcentaje,
									},
								])
							})
						}
					}
				}

				if(c.arce.hasOwnProperty('evaluation_arce')) {
					data.push(...[
						{
							field: parseKeys['evaluation_arce']['evaluation_arce'],
							data: '',
						},
						
					])

					let labels = parseKeys['evaluation_arce']
					let arce = c.arce['evaluation_arce']

					for (const key in arce) {
						data.push({
							field: labels[key] || key,
							data: arce[key],
						})
					}
				} 
				if(c.arce.hasOwnProperty('policies')) {
					data.push(...[
						{
							field: parseKeys['policies']['policies'],
							data: '',
						},
						
					])

					let labels = parseKeys['policies']
					let arce = c.arce['policies']

					for (const key in arce) {
						if(key !== 'politicas') {
							data.push({
								field: labels[key] || key,
								data: arce[key],
							})
						}
					}
				} 
			

			data.push(
				{
					field: '',
					data: '',
				},
				{
					field: 'FILIALES',
					data: '',
				},
				{
					field: '',
					data: '',
				},
			)
			this.arce.children.forEach(c => {
				data.push(...[
					{
						field: 'Filial',
						data: c.name,
					},
					{
						field: 'Datos Generales',
						data: '',
					},
					{
						field: 'Codigo',
						data: c.code,
					},
					{
						field: 'RFC',
						data: c.rfc,
					},
					{
						field: 'Cliente desde',
						data: c.clientSince,
					},
					{
						field: 'Actividad',
						data: c.activity,
					},
					{
						field: 'País',
						data: c.pais_cabecera,
					},
					{
						field: 'País',
						data: c.pais_cabecera,
					},
					{
						field: '',
						data: '',
					},
					{
						field: `ARCE /// ${c.name}`,
						data: '',
					},

				])

				if(c.arce.hasOwnProperty('analisis_basico')) {
					data.push(...[
						{
							field: parseKeys['analisis_basico']['analisis_basico'],
							data: '',
						},
						
					])

					let labels = parseKeys['analisis_basico']
					let arce = c.arce['analisis_basico']

					for (const key in arce) {
						 if(key == 'filiales') {
							data.push(...[
								{
									field: '',
									data:  '',
								},
								{
									field: 'Filiales',
									data:  '',
								},
								{
									field: '',
									data:  '',
								}
								
							])

							arce[key].forEach(item => {
								if(item.nombre && item.participacion) {
									
									data.push({
										field: 'Nombre',
										data:  item.nombre,
									})
									data.push({
										field: 'Participación',
										data:  item.participacion,
									})
									data.push({
										field: 'Actividad',
										data:  item.actividad,
									})
									data.push({
										field: 'Cliente BBVA',
										data:  item.clienteBBVA,
									})
									data.push({
										field: '',
										data:  '',
									})
								}
							})

						} else if(key == 'shareholders') {
								data.push({
									field: 'Accionistas',
									data:  '',
								})
								arce[key].forEach(sh => {
									if(sh.nombre && sh.participacion) {
										data.push({
											field: '',
											data:  '',
										})
										data.push({
											field: 'Nombre',
											data:  sh.nombre,
										})
										data.push({
											field: 'Participación',
											data:  sh.participacion,
										})
										data.push({
											field: 'Año',
											data:  sh.ano,
										})
										data.push({
											field: 'Año',
											data:  sh.financialSponsor,
										})
									}
								})
							}
							else {
								data.push({
									field: labels[key] || key,
									data: arce[key],
								})
							}
						}
				} 

				console.log(data)

				if(c.arce.hasOwnProperty('analisis_riesgo_del_negocio')){
					data.push(...[
						{
							field: parseKeys['analisis_riesgo_del_negocio']['analisis_riesgo_del_negocio'],
							data: '',
						},
						
					])
					let labels = parseKeys['analisis_riesgo_del_negocio']
					let arce = c.arce['analisis_riesgo_del_negocio']

					for (const key in arce) {
						if(!(['geografias', 'actividades', 'principalesClientes'].includes(key))) {
							data.push({
								field: labels[key] || key,
								data: arce[key],
							})
						} else if(key == 'geografias') {
							data.push({
								field: 'Geografías',
								data:  '',
							})
							arce[key].forEach(item => {
								if(item.nombre && item.porcentaje) {
									data.push(...[
										{
											field: 'Nombre',
											data: item.nombre,
										},
										{
											field: 'Porcentaje',
											data: item.porcentaje,
										},
										{
											field: 'Año',
											data: item.ano,
										}
									])
								}
							})
						} else if(key == 'actividades') {
							data.push({
								field: 'Actividades',
								data:  '',
							})

							arce[key].forEach(item => {
								if(item.nombre && item.porcentaje) {
									data.push(...[
										{
											field: 'Nombre',
											data: item.nombre,
										},
										{
											field: 'Porcentaje',
											data: item.porcentaje,
										},
										{
											field: 'Año',
											data: item.ano,
										}
									])
								}
							})
						}  else if(key == 'principalesClientes') {
							data.push({
								field: 'Pricipales clientes ',
								data:  '',
							})

							arce[key].forEach(item => {
								if(item.nombre && item.porcentaje) {
									data.push(...[
										{
											field: 'Nombre',
											data: item.nombre,
										},
										{
											field: 'Participación',
											data: item.participacion,
										}
									])
								}
							})
						}
					}
				} 
				console.log(data)
				
				if(c.arce.hasOwnProperty('analisis_industria')) {
					data.push(...[
						{
							field: parseKeys['analisis_industria']['analisis_industria'],
							data: '',
						},
						
					])
					let labels = parseKeys['analisis_industria']
					let arce = c.arce['analisis_industria']
					for (const key in arce) {
						data.push({
							field: labels[key] || key,
							data: arce[key],
						})
					}

				}
				console.log(data)
				
				if(c.arce.hasOwnProperty('analisis_riesgo_financiero')) {
					data.push(...[
						{
							field: parseKeys['analisis_riesgo_financiero']['analisis_riesgo_financiero'],
							data: '',
						},
						
					])
					let labels = parseKeys['analisis_riesgo_financiero']
					let arce = c.arce['analisis_riesgo_financiero']
					for (const key in arce) {
						if(!['eeff', 'tablaVencimientos'].includes(key)) {
							data.push({
								field: labels[key] || key,
								data: arce[key],
							})
						} else if(key === 'tablaVencimientos') {
							data.push({
								field: 'Tabla Vencimientos',
								data:  '',
							})

							arce[key].forEach(item => {
								if(item.nombre && item.importe) {
									data.push(...[
										{
											field: 'Nombre',
											data: item.nombre,
										},
										{
											field: 'Importe',
											data: item.importe,
										},
										{
											field: 'Año',
											data: item.ano,
										}
									])
								}
							})
						}
					}

				}
				console.log(data)
				
				if(c.arce.hasOwnProperty('rating_actual')) {
					data.push(...[
						{
							field: parseKeys['rating_actual']['rating_actual'],
							data: '',
						},
						
					])
					let labels = parseKeys['rating_actual']
					let arce = c.arce['rating_actual']
					for (const key in arce) {
						data.push({
							field: labels[key] || key,
							data: arce[key],
						})
					}
				}
				console.log(data)
				if(c.arce.hasOwnProperty('sumary_bank_relationship')) {
					data.push(...[
						{
							field: parseKeys['sumary_bank_relationship']['sumary_bank_relationship'],
							data: '',
						},
						
					])

					let labels = parseKeys['sumary_bank_relationship']
					let arce = c.arce['sumary_bank_relationship']

					for (const key in arce) {
						if(!['bancos', 'buro', 'posicionesRiesgo'].includes(key)) {
							data.push({
								field: labels[key] || key,
								data: arce[key],
							})
						} else if(key == 'posicionesRiesgo') {

							data.push(...[
								{
									field: '',
									data: '',
								},
								{
									field: 'Resumen posición de riesgo',
									data:  '',
								}
							])
							arce[key].forEach(item => {
								data.push(...[
									{
										field: '',
										data: '',
									},
									{
										field: 'Producto',
										data: item.producto,
									},
									{
										field: 'Límite actual',
										data: item.limiteActual,
									},
									{
										field: 'Comprometido',
										data: item.comprometido,
									},
									{
										field: 'No comprometido',
										data: item.noComprometido,
									},
									{
										field: 'Dispuesto',
										data: item.dispuesto,
									},
									{
										field: 'Comentarios/Garantías',
										data: item.comentariosGarantias,
									},
								])
							})
						} else if(key == 'buro') {
							data.push(...[
								{
									field: '',
									data: '',
								},
								{
								field: 'Buró',
								data:  '',
								}
							])
							arce[key].forEach(item => {
								data.push(...[
									{
										field: '',
										data: '',
									},
									{
										field: 'Nombre',
										data: item.producto,
									},
									{
										field: 'Fecha',
										data: item.fecha,
									},
									{
										field: 'Clave Prevención',
										data: item.clavePrevencion,
									},
									{
										field: 'Número de Bancos',
										data: item.numeroBancos,
									},
									{
										field: 'M.N',
										data: item.mn,
									},
									{
										field: 'USD Val',
										data: item.usd,
									},
									{
										field: 'UDIS Val',
										data: item.udis,
									},
									{
										field: 'Hasta 30 d',
										data: item.h30,
									},
									{
										field: 'Hasta 90 d',
										data: item.h90,
									},
									{
										field: 'Más de 90 d.',
										data: item.m90,
									},
								])
							})
						} else if(key == 'bancos') {
							data.push(...[
								{
									field: '',
									data: '',
								},
								{
								field: 'Bancos',
								data:  '',
								}
							])
							arce[key].forEach(item => {
								data.push(...[
									{
										field: '',
										data: '',
									},
									{
										field: 'Nombre',
										data: item.nombre,
									},
									{
										field: 'Porcentaje',
										data: item.porcentaje,
									},
								])
							})
						}
					}
				}
				console.log(data)

				if(c.arce.hasOwnProperty('evaluation_arce')) {
					data.push(...[
						{
							field: parseKeys['evaluation_arce']['evaluation_arce'],
							data: '',
						},
						
					])

					let labels = parseKeys['evaluation_arce']
					let arce = c.arce['evaluation_arce']

					for (const key in arce) {
						data.push({
							field: labels[key] || key,
							data: arce[key],
						})
					}
				} 
				console.log(data)
				
				if(c.arce.hasOwnProperty('policies')) {
					data.push(...[
						{
							field: parseKeys['policies']['policies'],
							data: '',
						},
						
					])

					let labels = parseKeys['policies']
					let arce = c.arce['policies']

					for (const key in arce) {
						if(key !== 'politicas') {
							data.push({
								field: labels[key] || key,
								data: arce[key],
							})
						}
					}
				} 
				console.log(data)

			})
			// ARCE_${userName}_${group.name}_${arce.folio}
			this.downloadExcel({
				name: `ARCE_${this.userName}_${this.group.name}_${this.arce.folio}`,
				fields: [{sheetid:'One',header:false}],
				data: [data]
			})
			// return data
		},
		select(id) {
			this.selected = id
			this.hiddenSteps()
			this.showSteps('stepsArceDetails', 8)
			let client = this.arce.children.find(c => c.id == id)

			if(client) {
				this.selectedDetails = {...client}
			} else {
				this.selectedDetails = {...this.clienById(this.selected)}
			}
		},

		getFill(form) {
			if(!this.selectedDetails) {
				return this.arce.arceFill[form] || 0
			}
			else if(this.selectedDetails.type == "Grupo") {
				return this.arce.arceFill[form] || 0
			}
			else if(this.selectedDetails.hasOwnProperty('arceFill')) {
				return this.selectedDetails.arceFill[form] || 0
			}

			return 0
		},
		updateArce() {
			this._updateArce(this.arce)
		},
	
		showValidationClient() {
			this.hiddenSteps()
			this.showModal('#validateClientModal')
			setTimeout(() => {
				this.validationIntro = false
				this.validationResult = true
				this.validateClient()
			}, 3000)
		},
		showValidationGroup() {
			this.hiddenSteps()
			this.showModal('#validateClientModal')
			setTimeout(() => {
				this.validationIntro = false
				this.validationResult = true
				this.validateGroup()
			}, 3000)
		},
		validateGroup() {
			this.arce.arceFill['validar_arce'] = 100
			this.arce.arce['validar_arce'] = {esValido: true}
			this._updateArce(this.arce)
		},
		validateClient() {
			let vm = this
			let payload =  {
				client:  this.selectedDetails,
				form: 'validar_arce',
				fill: 100,
				data: {esValido: true},
			}
			vm._setDataChild(payload)
			this._updateArce(this.arce)
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},
		clientArceValid(client) {
			
			if(client.hasOwnProperty('arceFill')) {
				return client.arceFill['validar_arce'] == 100
			}

			return false
		},

		proposedArce() {
			
			if(!this.proposedSancion) {
				return  this.$notify({
					type: 'warn',
					group: 'simulator',
					title: 'Selecciona Alcance de Sanción',
					text: 'Debes seleccionar Alcance de la Sanción para poder continuar'
				})
			}
			this.loaderProposed = true
			setTimeout(() => {
				let data = {...this.arce}
				data.status = 'sanction'
				this._updateArce(data)
				this.loaderProposed = false
			}, 3000)
		},
		...mapMutations({
			_setDataChild: 'arceDetails/setDataChild',
			showArceWizard: 'arceStatus/showArceWizard',
			initArceWizard: 'arceStatus/initArceWizard',
		}),
		...mapActions({
			getArce: 'arceDetails/getArce',
			_updateArce: 'arceDetails/updateArce'
		}),
	},

	created() {
		this.initWizard();
		this.eventBusOn('downloadProgress', this.excelData)
		this.eventBusOn('downloadPolicies', this.excelPoliciesData)
		this.getArce(this.$route.params.id).then(() => {
			this.selected = this.arce.groupId
			this.selectedDetails = {...this.clienById(this.selected)}
			this.showSteps('stepsArceDetails')
		})
		console.log(parseKeys)
	},
	beforeDestroy() {
		this.eventBusOff('downloadProgress', this.excelData)
		this.eventBusOff('downloadPolicies', this.excelPoliciesData)

	}
}
</script>
<template>
	<div class="d-flex flex-wrap px-1 py-1 s-single">
		<!-- validateClientModal -->
		<div class="modal s-modal fade" id="validateClientModal" tabindex="-1" role="dialog" aria-labelledby="validateClientModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-md" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 v-if="validationIntro" class="modal-title w-100 text-center" id="validateClientModalLabel">Información: Debe verificar los registros insertados, verificar que la filial no sobrepase el techo de grupo y contemplar los errors marcados en la siguiente tabla:</h5>
						<h5 v-else-if="validationResult" class="modal-title w-100 text-center" id="validateClientModalLabel">Información</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>	
					<div class="modal-body">
						<div v-if="validationIntro">
							<table class="s-table w-100">
								<thead>
									<tr>
										<th>
											Cliente
										</th>
										<th>
											Campo
										</th>
										<th>
											Tipología
										</th>
										<th>
											Techo
										</th>
									</tr>
								</thead>
								<tbody>
									
								</tbody>
							</table>
						</div>
						<div v-else-if="validationResult" class="text-center" >
							<h2 style="font-size: 1.2em">ARCE Validado</h2>
							<p>La validación fue Exitosa!</p>
						</div>
						<div class=" p-2 bg-light mx-n3 mb-n3">
							<div class="text-right">
								<button class="s-btn s-btn--primary s-btn--outline mx-1" data-dismiss="modal">Cerrar</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- proposedArceModal -->
		<div class="modal s-modal fade" id="proposedArceModal" tabindex="-1" role="dialog" aria-labelledby="proposedArceModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-md" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title w-100 text-center" id="proposedArceModalLabel">Proponer</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>	
					<div class="modal-body">
						<div class="pb-5" v-if="arce">
							<div class="text-center pb-2" v-if="arce.status == 'sanction'">
								<span class="tag-arce">
									<span class="mx-1 d-inline-block p-1">
										<span class="badge-success">
											<SvgIcon name="check" />
										</span>
									</span>
									ARCE asignado corretamente
								</span>
							</div>
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Alcance de la sanción</p>
								<select class="form-control w-100" name="gupo" v-model="proposedSancion">
									<option value="Riesgo">Riesgo</option>
									<option value="" disabled></option>
								</select>
							</label>
						</div>
						<div class=" p-2 bg-light mx-n3 mb-n3 mt-5">
							<div class="text-right">
								<button class="s-btn s-btn--primary s-btn--outline mx-1" data-dismiss="modal">Cerrar</button>
								<button class="s-btn s-btn--primary mx-1" @click="proposedArce">Guardar</button>
							</div>
						</div>
					</div>
					<div v-if="loaderProposed" class="s-modal__loader">
						<img class="m-auto gray" src="@/assets/simulator/loader.gif" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 p-0">
			<div class="d-flex flex-wrap py-1">
				<div class="col-12 px-2 py-2" style="position: sticky; top: 98px; z-index: 101">
					<div class="s-panel">
						<div class="s-panel__header bg-light px-3 py-2">
							<div class="d-flex align-items-center">
								<div class="s-element__icon">
									<img src="@/assets/simulator/account_120.png" alt="">
								</div>
								<div>
									<p class="m-0">ARCE</p>
									<h3 class="title" v-if="group">
										Nuevo - {{group.name}} - <span data-v-step="ad1">{{arce.folio}}</span>
									</h3>
								</div>
								<div class="ml-auto d-flex">
									<!-- <button class="s-btn s-btn--success mx-2" @click="excelData">
										Descargar Avance 
										<SvgIcon name="download" />
									</button>
									<button class="s-btn s-btn--success mx-2" @click="excelPoliciesData">
										Descargar Políticas 
										<SvgIcon name="download" />
									</button> -->
									<!-- <export-excel v-if="arce && group"
										
										:data="excelData()"
										:fields="json_fields"
										type="csv"
										:name="`ARCE_${userName}_${group.name}_${arce.folio}.xls`">
										
									</export-excel> -->
									<!-- <export-excel v-if="arce && group"
										class="s-btn s-btn--success mx-2"
										:data="excelPoliciesData()"
										:fields="json_policies_fields"
										type="csv"
										:name="`ARCE_Tabla_politicas_${userName}_${group.name}_${arce.folio}.xls`">
										Descargar Políticas 
										<SvgIcon name="download" />
									</export-excel>
									<button class="s-btn s-btn--outline">
										<SvgIcon name="add"/>
										Seguir
									</button> -->

								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 px-2 py-2">
					<div class="s-panel" data-v-step="ad2">
						<div class="s-panel__body">
							<ul class="s-header-steps" v-if="arce">
								<li class="s-header-steps__item" :class="{
									's-header-steps__item--completed': (arce.status == 'finished'),
									's-header-steps__item--completed': (arce.status == 'sanction'),
									's-header-steps__item--current': (arce.status == 'prepair'),
								}">
									<span>
										IN PREPARATION
									</span>
								</li>
								<li class="s-header-steps__item" :class="{
									's-header-steps__item--completed': (arce.status == 'finished'),
									's-header-steps__item--completed': (arce.status == 'finished'),
									's-header-steps__item--current': (arce.status == 'sanction'),
								}">
									<span>
										EN SANCTION
									</span>
								</li>
								
								
								<li class="s-header-steps__item">
									<span>
										FINALIZED
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__body ">
							<div class="d-flex" v-if="arce">
								<div class="ml-auto" v-if="arce.status == 'prepair'">
									<template v-if="selectedDetails">
										<button v-if="selectedDetails.type == 'Cliente' && !clientArceValid(selectedDetails)" class="s-btn s-btn--primary" @click="showValidationClient" data-v-step="sctr1">
											Validar Cliente
										</button>
										<button v-if="selectedDetails.type == 'Grupo' && !clientArceValid(arce)" class="s-btn s-btn--primary" @click="showValidationGroup">
											Validar Grupo
										</button>
										<button v-else-if="clientArceValid(arce) && arce.status == 'prepair' && selectedDetails.type == 'Grupo'"  class="s-btn s-btn--primary" @click="showModal('#proposedArceModal')">
											Proponer
										</button>
									</template>
									<button class="s-btn s-btn--primary ml-2">
										Retirar
									</button>
									<button class="s-btn s-btn--primary ml-2">
										Refrescar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__body ">
							<div class="text-center" data-v-step="ad7" style="white-space: nowrap; overflow: auto">
								<div v-if="group" class="col-3 px-1 d-inline-block" >
									<div class="border p-3 s-arce__client"
									:class="{'s-arce__client--selected': group.id == selected}"
									@click="select(group.id)">
										<!-- <h5>{{group.name}}</h5> -->
										<H6 class="mb-0">TUM TRANSPORTISTAS UNIDOS ...</H6>
										<p><b>Parent Company</b></p>
										<div class="px-2">
											<p class="mb-1">Alpha code</p>
											<p class="mb-1">MX0074022007739</p>
											<p class="mb-1">RIC CODE</p>
											<p>TTU97007DM2</p>
										</div>
									</div>
								</div>
								<div v-for="(child, index) in (arce ?( arce.children || []) : [])" :key="`c_c_${index}`" class="col-3 px-1 d-inline-block">
									<div class="border p-3 s-arce__client"
									:class="{'s-arce__client--selected': child.id == selected}"
									@click="select(child.id)" data-v-step="ad8">
										<h5>{{child.name}}</h5>
										<p>Filial / Subsidiaria</p>
										<div class="px-2">
											<p>EEFF</p>

											<p>Estado</p>
											<p>En Preparación</p>
											<p>Exposición</p>
										</div>
									</div>
								</div>
							</div>

							<div class="s-panel__controls">
								<button class="s-btn s-btn--outline py-1 px-2 my-2 mx-4">
									<span style="font-size: 1.5em">
										<svg-icon name="change_record_type" />
									</span>
								</button>

								<!-- <button class="s-btn s-btn--outline py-1 px-2 my-2 mx-4" data-toggle="modal" data-target="#groupStructure" data-v-step="ad3" @click="showSteps('stepsArceDetails', 3)">
									<span style="font-size: 1.5em">
										<svg-icon name="add" />
									</span>
								</button> -->
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 px-2 py-2" v-if="selectedDetails">
					<div :key="`clienhte_${selectedDetails.id}`"  class="s-panel" data-v-step="ad9">
						<div class="s-panel__body " >
							<div class="s-nav-tab nav nav-tabs">
								<button class="s-nav-tab__item active" @click="() => {hiddenSteps()}"  data-toggle="tab" href="#sectionAnalysis" role="tab" aria-controls="FormAnalysisBasic" aria-selected="true">
									Analysis
								</button>
								<button v-if="selectedDetails.type == 'Cliente'" class="s-nav-tab__item " @click="() => {hiddenSteps(), showSteps('stepsRating')}"  data-toggle="tab" href="#sectionRating" role="tab" aria-controls="FormAnalysisBasic" aria-selected="true">
									Rating
								</button>
								
								<!-- <button class="s-nav-tab__item" @click="() => {hiddenSteps()}" data-toggle="tab" href="#sectionSumaryBankRelationship" role="tab" aria-controls="sectionSumaryBankRelationship" aria-selected="true">
									Relación bancaria ({{getFill('sumary_bank_relationship')}}%)
								</button>

								<button class="s-nav-tab__item" @click="() => {hiddenSteps()}" data-toggle="tab" href="#sectionFormEvaluation" role="tab" aria-controls="sectionFormEvaluation" aria-selected="true">
									Evaluación ({{getFill('evaluation_arce')}}%)
								</button>

								<button v-if="checkAllForms" data-v-step="sp1" @click="() => {hiddenSteps()}" class="s-nav-tab__item" data-toggle="tab" href="#sectionFormPolicies" role="tab" aria-controls="sectionFormPolicies" aria-selected="true">
									Políticas
								</button>
								<span v-else data-v-step="sp1"></span>
								<button class="s-nav-tab__item" data-toggle="tab" href="#sectionResumen" role="tab" aria-controls="sectionResumen" aria-selected="true" @click="() => {hiddenSteps()}">
									Resumen Ejecutivo
								</button> -->
							</div>
							<div class="py-3">
								<div class="border p-3">
									<div class="d-flex justify-content-center" v-if="selected">
										<div class="col-auto px-5">
											<p class="m-0">Name</p>
											<div class="d-flex align-items-center">
												<div class="s-element__icon s-element__icon--24">
													<img src="@/assets/simulator/account_120.png" alt="">
												</div>
												<div>
													<p class="m-0" >
														{{selected.name}}
													</p>
												</div>
											</div>
										</div>
										<div class="col-auto px-5">
											<p class="m-0">Folio</p>
											<div class="d-flex align-items-center">
												<div class="s-element__icon s-element__icon--24">
													<img src="@/assets/simulator/catalog_120.png" alt="">
												</div>
												<div>
													<p class="m-0" >
														{{arce.folio}}
													</p>
												</div>
											</div>
										</div>
										<div class="col-auto px-5">
											<p class="m-0">FortiMax</p>
											<div class="d-flex align-items-center">
												
												<div>
													<p class="m-0" >
														<a href="/fortimax.png" target="_blank">
															http://imaxwebngnnv.intranet.com.mx:8081/imaxweb/index.jsp
														</a>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-content">
								<div id="sectionAnalysis" class="tab-pane fade show active">
									<div class="card">
										<div class="card-body">
											<div class="row">
												<div class=" col-2">
												</div>	
												<div class=" col-6">
													<p class="mb-0">Name</p>
													<button class="btn s-element__icon btn-icon btn-sm">
														<svg-icon name="builder" />
													</button>
													<h5 class="ml-4">UM TRANSPORTISTAS UNIDOS MECICANOS DIVISION NORTE, SA DE CV</h5>
												</div>
												<div class="col-4">
													<p class="mb-0">Folio</p>
													<button class="btn  btn-icon btn-info btn-sm">
														<svg-icon name="builder" />
													</button>
													<h5 class="ml-4">0000000008059</h5>
												</div>
											</div>
										</div>
									</div>
									<div class="nav nav-tabs" style="background: #EFEFEF">
										<button class="nav-item nav-link active"  data-toggle="tab" @click="() => {hiddenSteps()}" href="#FormBusinessRisk" role="tab" aria-controls="FormBusinessRisk" aria-selected="true">
											Basic Data 
										</button>
										<button class="nav-item nav-link" data-v-step="ad12"  data-toggle="tab" @click="() => {hiddenSteps()}" href="#FormBusinessRisk" role="tab" aria-controls="FormBusinessRisk" aria-selected="true">
											business risk 
										</button>
										<button v-if="selectedDetails.type == 'Cliente'" class="nav-item nav-link" @click="() => {hiddenSteps()}"  data-toggle="tab" href="#FormAnalysisIndustry" role="tab" aria-controls="FormAnalysisIndustry" aria-selected="true">
											Industry analysis 
										</button>
										<button v-if="selectedDetails.type == 'Cliente'" class="nav-item nav-link" @click="() => {hiddenSteps(), showSteps('stepsAnalysisFinancialRisk')}" data-toggle="tab" href="#FormAnalysisFinancialRisk" role="tab" aria-controls="FormAnalysisIndustry" aria-selected="true">
											Financial risk 
										</button>
										<button v-else class="nav-item nav-link" @click="() => {hiddenSteps()}" data-toggle="tab" href="#FormAnalysisFinancialRisk" role="tab" aria-controls="FormAnalysisIndustry" aria-selected="true">
											Financial risk
										</button>
									
										
									</div>
									<div class="tab-content">
										<div id="FormAnalysisBasic" class="tab-pane fade " >
											<div  v-if="selectedDetails.type == 'Cliente'">
												<FormAnalysisBasic/>
											</div>
											<div v-else>
												<FormAnalysisBasicGroup :client="arce"  @updateArce="updateArce"/>
											</div>
										</div>
										<div id="FormBusinessRisk" class="tab-pane fade show active">
											<div  v-if="selectedDetails.type == 'Cliente'">
												<FormBusinessRisk :client="selectedDetails"  @updateArce="updateArce"/>
											</div>
											<div v-else>
												<FormBusinessRiskGroup :client="arce" @updateArce="updateArce"/>
											</div>
										</div>
										<div  v-if="selectedDetails.type == 'Cliente'" id="FormAnalysisIndustry" class="tab-pane fade">
											<FormAnalysisIndustry :client="selectedDetails" @updateArce="updateArce"/>
										</div>
										<div id="FormAnalysisFinancialRisk" class="tab-pane fade">
											<div v-if="selectedDetails.type == 'Cliente'">
												<FormAnalysisFinancialRisk :client="selectedDetails" @updateArce="updateArce"/>
											</div>
											<div v-else>
												<FormAnalysisFinancialRiskGroup :client="arce" @updateArce="updateArce"/>
											</div>
										</div>
									</div>
								</div>
								<div v-if="selectedDetails.type == 'Cliente'" id="sectionRating" class="tab-pane fade">
									<div class="nav nav-tabs">
										<button class="nav-item nav-link active"  data-toggle="tab" href="#FromCurrentRating" role="tab" aria-controls="FromCurrentRating" aria-selected="true">
											Rating Actual
										</button>
										<button class="nav-item nav-link"  data-toggle="tab" href="#FromStudioRating" role="tab" aria-controls="" aria-selected="true">
											Estudios
										</button>
									</div>
									<div class="tab-content">
										
											<FormRating :client="selectedDetails"  @updateArce="updateArce"/>
										
									</div>
								</div>
								<div id="sectionSumaryBankRelationship" class="tab-pane fade">
									<div v-if="selectedDetails.type == 'Cliente'">
										<SumaryBankRelationship :client="selectedDetails"  @updateArce="updateArce"/>
									</div>
									<div v-else>
										<SumaryBankRelationshipGroup :client="arce" @updateArce="updateArce"/>
									</div>
								</div>
								<div id="sectionFormEvaluation" class="tab-pane fade">
									<div v-if="selectedDetails.type == 'Cliente'">
										<FormEvaluation :client="selectedDetails"  @updateArce="updateArce"/>
									</div>
									<div v-else>
										<FormEvaluationGroup :client="arce" @updateArce="updateArce"/>
									</div>
								</div>
								<div id="sectionFormPolicies" class="tab-pane fade">
									<div v-if="selectedDetails.type == 'Cliente'">
										<FormPolicies :client="selectedDetails"  @updateArce="updateArce"/>
									</div>
									<div v-else>
										<FormPoliciesGroup :client="arce" @updateArce="updateArce"/>
									</div>
								</div>
								<div id="sectionResumen" class="tab-pane fade">
									<p></p>
									<iframe src="/expediente/soriano/resumen_arce.pdf#toolbar=0&zoom=150" class="w-100" style="height: 100vh" frameborder="0"></iframe>
									<div class="text-center py-2">
										<button class="s-btn s-btn--primary">Imprimir</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__header bg-light p-2">
							<h3 class="title" v-if="group">
								Trazabilidad
							</h3>
						</div>
						<div class="s-panel__body ">
							<div class="s-traz mb-1">
								<div class="s-traz__check">
									<svg-icon name="check" />
								</div>
								<div>
									<h6 class="m-0">Preparando Análisis</h6>
									<div>
										<div class="d-flex mb-1">
											<span class="s-traz__user mr-2">
												<img src="@/assets/simulator/user_120.png" alt="">
											</span>
											<p class="m-0">{{userName}}</p>
										</div>
										<p class="m-0 small text-muted">
											Etapa: En Preparación | Estado: Preparando Análisis | Código Usuario {{userName}} | Oficina: Oficina | Subproceso: Nuevo Análisis
										</p>
									</div>
								</div>
							</div>
							<div class="s-traz mb-1">
								<div class="s-traz__check">
									<svg-icon name="check" />
								</div>
								<div>
									<h6 class="m-0">Preparando Análisis</h6>
									<div>
										<div class="d-flex mb-1">
											<span class="s-traz__user mr-2">
												<img src="@/assets/simulator/user_120.png" alt="">
											</span>
											<p class="m-0">{{userName}}</p>
										</div>
										<p class="m-0 small text-muted">
											Etapa: En Preparación | Estado: Preparando Análisis | Código Usuario {{userName}} | Oficina: Oficina | Subproceso: Nuevo Análisis
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="arce">
					<div v-if="arce.status == 'sanction'" class="col-12 px-2 py-2">
						<div class="s-panel">
							
							<div class="s-panel__body ">
								<div class="d-flex align-items-end">
									<div class="">
										<p class="m-0">Order por:</p>
										<div>
											<button class="chater-button">Actividad más reciente</button>
										</div>
									</div>

									<div class="ml-auto">
										<div class="d-flex">
											<div class="chater-button">
												<input placeholder="Buscar en noticias.." type="text">
											</div>
											<button class="chater-button ml-2">
												<SvgIcon name="refresh" />
											</button>
										</div>
										
									</div>
								</div>
								<div>
									<div class="mt-3">
										<div class="d-flex align-items-center">
											<div class="mx-2  s-header__avatar">
												<img src="@/assets/simulator/profile_avatar_96.png" alt="">
											</div>
											<div>
												<span class="text-primary">ALAN DAMIAN MERNO SANDOVAL</span> ha actualizado este registro.
												<p class="m-0">Ahora mismo.</p>
											</div>
										</div>
									</div>

									<div class="border rounded pt-5 pb-2 px-3 mt-3">
										<p class="m-0">Stage</p>
										<p>EN PREPARACIÓN A EN SANCIÓN</p>
									</div>
									<div class="d-flex py-1">
										<span class="d-flex align-items-center py-2 px-3" style="color: #6d6d6d">
											<SvgIcon name="like"/>
											Me gusta
										</span>
										<span class="d-flex align-items-center py-2 px-3" style="color: #6d6d6d">
											<SvgIcon name="share_post"/>
											Comentario
										</span>
									</div>

									<div class="bg-light border py-2 px-3">
										<div class="d-flex">
											<div class="mx-2  s-header__avatar">
												<img src="@/assets/simulator/profile_avatar_96.png" alt="">
											</div>
											<div class="chater-button w-100 overflow-hidden">
												<input class="w-100" type="text" placeholder="Escribir un comentario...">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>